@charset 'utf-8';

@import 'settings';
@import 'components/layout_helpers';

#page_container > .column {
  margin: 0;
  padding: 0;

  #content_container {
    min-height: auto;
  }
}

.error-background {
  position: relative;

  video {
    width: 100%;
    z-index: 1;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 3em 3em 0;
    z-index: 2;
  }

  h1 {
    color: $white;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    color: $white;
    font-size: 1rem;
  }
}
